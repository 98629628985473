import {
  Category,
  Chat,
  Folder,
  Message,
  Password,
  People,
  User,
  Work,
} from "react-iconly";

const pages = [
  {
    header: "UTAMA",
  },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Category set="curved" className="remix-icon" />,
    navLink: "/dashboard",
    access: null,
  },
  {
    header: "LAINNYA",
  },
  {
    id: "system",
    title: "Sistem",
    icon: <Work set="curved" className="remix-icon" />,
    navLink: "/system/data",
    access: ["system-index"],
  },
  {
    id: "master-data",
    title: "Master Data",
    icon: <Folder set="curved" />,
    access: ["probability-index", "criteria-index"],
    children: [
      {
        id: "kriteria",
        title: "Kriteria",
        navLink: "/criteria/data",
        access: ["criteria-index"],
      },
      {
        id: "bobot",
        title: "Pembobotan",
        navLink: "/bobot/data",
        access: ["probability-index"],
      },
      {
        id: "bobot-reject",
        title: "Pembobotan Reject",
        navLink: "/bobot-reject/data",
        access: ["probability-reject-index"],
      },
      {
        id: "keterangan-reject",
        title: "Keterangan Reject",
        navLink: "/keterangan-reject/data",
        access: ["keterangan-reject-index"],
      },
      {
        id: "insurance-fee",
        title: "Biaya Lainnya",
        navLink: "/insurance-fee/data",
        access: ["probability-index"],
      },
      // {
      //   id: "telemarketing",
      //   title: "Telemarketing",
      //   navLink: "/telemarketing/data",
      //   access: ["user-index"],
      // },
    ],
  },
  {
    id: "user",
    title: "Manajemen User",
    icon: <People set="curved" className="remix-icon" />,
    navLink: "/user/data",
    access: ["user-index"],
  },
  {
    id: "role",
    title: "Role & Permission",
    icon: <Password set="curved" className="remix-icon" />,
    navLink: "/role/data",
    access: ["role-index"],
  },
  {
    id: "profile",
    title: "Profile",
    icon: <User set="curved" className="remix-icon" />,
    navLink: "/profile/my-profile",
    access: null,
  },
];

export default pages;
